html {
  font-size: 54.5%; /* 16px x 62.5 = 10 px = 1 rem */
  box-sizing: border-box;
}
body {
  margin: 0;
  height: 100vh;
  font-size: 1.6rem;
  font-family: DrukWide-Super, "Fellix", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

/* Layout    */

.grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
}

header {
  grid-area: header;
  background-color: rgb(25, 27, 28);
  position: fixed;
  top: 0;
  width: 100%;
  height: 7rem;
  z-index: 300;
}

main {
  grid-area: main;

  padding-top: 2rem;
  background-color: rgb(12, 12, 14);
}

@media screen and (max-width: 500px) {
  header {
    height: 6rem;
  }
  main {
    padding-top: 0rem;
  }
}

.mainContent {
  padding-left: 2rem;
  padding-right: 2rem;
}

/* Common */

h1 {
  font-size: 3rem;
  color: white;
  text-align: left;
}

.coverInside h1 {
  font-size: 4.5rem;
  margin-top: 0rem;
}

@media screen and (max-width: 850px) {
  .coverInside h1 {
    font-size: 3rem;
  }
}

h2 {
  color: white;
  font-size: 3rem;
  text-align: left;
}

h3 {
  color: white;
  font-size: 2rem;
}

.twoThird h3 {
  font-size: 2.6rem;
  margin-bottom: 2rem;
}

li {
  margin-top: 1rem;
  margin-bottom: 1.1rem;
  /*color: green !important; */
  /* text-decoration: none !important; */
  color: inherit;
  list-style-type: none;
}

p {
  margin-top: 0rem;
  margin-bottom: 3rem;
}

input,
textarea {
  color: rgb(12, 12, 14);
  padding: 1rem;
  font-size: 1.6rem;
  border-radius: 00.2rem;
  /* margin-right: 6rem;  */
  border: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Fellix", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  border-radius: 8px;
}

.search input {
  padding: 2rem;
}

select,
button {
  padding: 1rem;
  font-size: 1.6rem;
  border-radius: 40px;
  border: none;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

textarea {
  width: 90%;
  max-width: 70rem;
  height: 12rem;
  color: rgb(12, 12, 14);
  min-width: 26rem;
}

#settings textarea {
  max-width: 35rem;
  height: 5rem;
}

button {
  background-color: #fb3640;
  cursor: pointer;
}

.duocolorbutton {
  background: -webkit-linear-gradient(45deg, #fb3640, #fd9096);
  justify-content: center;
  min-width: 8rem;
}

.duocolorbutton:hover {
  background: -webkit-linear-gradient(68deg, #fb3640, #fd9096);
}

select:hover,
button:hover {
  background-color: #f8454e;
}

a.button2 {
  padding: 1rem;
  font-size: 1.6rem;
  border-radius: 40px;
  border: none;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #fb3640;
  cursor: pointer;
}

.buttonCheck {
  background-color: rgb(0, 170, 0);
  margin-right: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.buttonCheck:hover {
  background-color: rgb(0, 193, 0);
}

a {
  text-decoration: none;
  color: inherit;
  color: white;
  font-weight: bold;
}

li text {
  vertical-align: 50%;
}

a.button2:hover {
  background-color: #f8454e;
  color: white;
  padding: 1.2rem;
}

#italic {
  font-style: italic;
}

/*
a:visited {
  color: white;
}
*/

ul {
  padding: 0;
  margin: 0;
}

td {
  padding-right: 4rem;
}

div {
  color: white;
}

p {
  white-space: pre-wrap;
}

hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  height: 0.001px;
  border-style: solid;
  margin: 2px;
  border-width: 1px;
  border-color: rgb(29, 31, 34);
}

.buttonPlaylistDelete {
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0);
}

.buttonPlaylistDelete:hover {
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0);
}

.buttonPlaylistDelete .fa-solid {
  color: rgba(255, 255, 255, 0.75);
}

.buttonPlaylistDelete .fa-solid:hover {
  color: white;
}

.buttondelete {
  background-color: rgb(61 54 66);
}

.buttondelete .fa-solid {
  color: #fc5e66;
}

.buttondelete:hover {
  background-color: rgb(71, 66, 76);
}

.informationPointer {
  margin-left: auto;
  margin-right: 1rem;
}

#darkBlue {
  background-color: rgb(25, 27, 28);
}

#darkBlue:hover {
  background-color: rgb(33, 35, 36);
}

#darkGrey {
  background-color: rgb(25, 27, 28);
}

/* Header */
header a {
  color: rgba(255, 255, 255, 0.75);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

header a:hover {
  color: white;
}

a.brand {
  /*color: -webkit-linear-gradient(#eee, #333); */
  font-size: 4rem;
  font-weight: bold;
  background: -webkit-linear-gradient(45deg, #fb3640, #0582ca);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.badge {
  background-color: #fb3640;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  margin-left: 0.4rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.row.center {
  justify-content: center;
}

.review .center {
  justify-content: left;
}

.row.top {
  align-items: flex-start;
}

.col-1 {
  min-width: 26rem;
  /*max-width: 35rem;*/
  /*flex: 1 1 25rem;*/
  justify-content: center;
}

.col-2 {
  flex: 1 1 30rem;
  /*text-align: right;*/
}

.col-3 {
  max-width: 80%;
  align-content: center;
}

.min-30-2 {
  min-width: 30rem;
}

tr {
  color: white;
}

.buttonLogin {
  background-image: linear-gradient(144deg, #fb3640, #fd9096);
  border: 0;
  border-radius: 50px;
  line-height: 4rem;
  max-width: 100px;
  min-width: 80px;
  padding: 3px;
  transition: transform 0.1s ease;
}

.buttonLogin:hover {
  transform: scale(1.05);
  background: -webkit-linear-gradient(4deg, #fb3640, #fd9096);
}

.noHover:hover {
  background-color: rgb(25, 27, 28);
}

.space {
  padding: 1rem;
}

.hoverMouse {
  cursor: pointer;
}

header .fa-magnifying-glass {
  font-size: 2rem;
  margin-left: auto;
  margin-right: 3rem;
  cursor: pointer;
}

/*
 * Just a quick hamburger
 */

#menuToggleHam {
  padding-top: 1rem;
}

#menuToggleHam span {
  /* right: -90rem;  */
  display: block;
  width: 36px;
  height: 4px;
  margin-bottom: 6px;
  position: relative;
  background: white;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  background: 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggleHam a {
  padding: 0rem;
  color: white;
}

#menuToggleHam span:first-child {
  transform-origin: 0% 0%;
}

#menuToggleHam span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggleHam input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: white;
}

/*
 * But let's hide the middle one.
 */
#menuToggleHam input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggleHam input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menuHam {
  position: absolute;
  width: 280px;
  margin-top: -100px;
  padding: 34px;
  padding-top: 100px;
  border-radius: 8px;
  right: -40rem;
  background: rgb(44, 39, 48);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  font-size: 30px;

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menuHam li {
  padding: 10px 0;
  font-size: 20px;
}

/*
 * And let's slide it in from the left
 */
#menuToggleHam input:not(:checked) ~ ul {
  transform: none;
}

#menuToggleHam input {
  float: right;
  width: 5rem;
  padding-top: 2rem;
  position: relative;
  z-index: 10 !important;
}

#menuToggleHam input[type="checkbox"] {
  opacity: 0;
  height: 3rem;
}

#menuToggleHam .search input {
  opacity: 1;
  width: 190px;
}

#menuToggleHam .search .row {
  justify-content: left;
}

#menuHam .fa-solid {
  margin-right: 1rem;
}

/* COVER */

.twoThird {
  width: 70%;
  min-width: 150px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 17px;
}
@media screen and (max-width: 950px) {
  .twoThird {
    width: 100%;
    margin-right: 0rem;
  }
}

.half {
  width: 47%;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin-left: -2px;
  padding-left: 0px;
  padding-right: 3px;
  overflow: hidden;
}

.oneThird {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}

#thrdColor .oneThird {
  border-radius: 1.5rem;
}

#leftAlign {
  text-align: left;
}

.headerPicture {
  height: 24rem;
}

#darkGrey .headerPicture {
  width: 90%;
  height: auto;
  max-height: 34rem;
}

.menuBar {
  margin-right: 3rem;
  display: flex;
  align-items: center;
}

h4 {
  font-size: 4.5rem;
  color: white;
  text-align: left;
  display: block;
  font-family: Helvetica, "Segoe UI", Tahoma, Arial, sans-serif;
  margin-top: 0rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 850px) {
  h4 {
    font-size: 4.1rem;
  }
}

.cover {
  padding-top: 2rem;

  padding-left: 5rem;
  padding-right: 5rem;
  background-color: #162e3c;
  padding-bottom: 2rem;
}

@media screen and (max-width: 850px) {
  .cover {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.mainElement {
  padding-left: 5rem;
  padding-right: 5rem;
}

@media screen and (max-width: 900px) {
  .mainElement {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.coverInside {
  text-align: center;
  max-width: 160rem;
  margin-left: auto;
  margin-right: auto;
}

.mainInside {
  max-width: 160rem;
  margin-left: auto;
  margin-right: auto;
}

/* 
.coverInside img {
  width: 10rem !important;
  margin-left: 20rem;
  margin-bottom: 5rem;
  vertical-align: middle;
  border: 0.1rem solid white; 
  -webkit-filter: invert(100%);
}
*/

.coverInside .fa-solid {
  padding-left: 1rem;
  padding-top: 2rem;
}

.coverInside p {
  margin-bottom: 0rem;
}

#coverReviews .likeReview {
  margin-right: 0.5rem;
}

.logos {
  display: inline-block;
}

#scdColor {
  background-color: #2e3045;
}

#thrdColor {
  background-color: #4c5866;
}

#headerHP .coverInside {
  max-width: 110rem;
}

#headerHP .coverInside .twoThird {
  max-width: 65rem;
  margin-left: 6rem;
}

#headerHP .coverInside {
  padding-right: 2rem;
}

@media screen and (max-width: 972px) {
  #headerHP .coverInside .oneThird img {
    height: 100%;
    max-height: 25rem;
  }
  #headerHP .coverInside .oneThird {
    width: 100%;
  }

  #headerHP .coverInside .twoThird {
    margin-left: 0rem;
    text-align: center;
  }
  #headerHP h1 {
    text-align: center;
  }
  #headerHP .coverInside {
    padding-right: 0rem;
  }
}

@media screen and (max-width: 550px) {
  #headerHP .coverInside .twoThird p {
    max-width: 33rem;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Menu */

.fiftyPercent {
  min-width: 48%;
  min-width: 120px;
  max-width: 680px;
  display: inline-block;
  margin-left: 2rem;
  margin-right: 2rem;
}

.fortyPercent {
  min-width: 34%;
  min-width: 140px;
  max-width: 550px;
  display: inline-block;
  margin-left: 2rem;
  margin-right: 2rem;
}

#max-width-680 {
  max-width: 680px;
}

@media screen and (max-width: 850px) {
  .fiftyPercent {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media screen and (max-width: 1500px) {
  .fiftyPercent {
    max-width: 600px;
  }
}

/*
  background-color: rgb(25, 27, 28);
    background-color: rgb(44, 39, 48);  */

.buttonMenu {
  background-color: rgb(25, 27, 28);
  margin: 2rem auto;
  border-radius: 8px;
  padding-bottom: 5rem;
  max-width: 100rem;
}

.buttonMenu .form {
  padding-right: 2rem;
  padding-left: 2rem;
}

.buttonMenu:hover {
  /* background-color: rgb(44, 39, 48);  */
  cursor: pointer;
}

.pictureNeomorph {
  /* box-shadow: 14px -14px 29px #111111, -14px 14px 29px #313131;  */
  width: 100%;
  max-width: 1800px;
}

.fiftyPercent p,
.fortyPercent p {
  font-weight: 250;
  font-size: 2rem;
}

.buttonMenu p {
  padding-left: 2rem;
  padding-right: 2rem;
}

.channelOfWeek {
  border-radius: 8px;
  display: inline-block;
}

.channelOfWeek iframe {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 850px) {
  .channelOfWeek {
    margin-left: -1rem;
    margin-right: -1rem;

    max-width: 830px;
  }
  .fortyPercent {
    padding: 0rem;
  }
}

.coverInside #pictureHP {
  height: 26rem;
}

@media screen and (max-width: 1330px) {
  .channelOfWeek {
    max-width: 650px;
  }
}

.channelOfWeek .fortyPercent,
.channelOfWeek .fiftyPercent {
  text-align: left;
  vertical-align: middle;
}

@media screen and (min-width: 1330px) {
  .channelOfWeek .fortyPercent {
    max-width: 44rem;
  }
}

.channelOfWeek .rating .fa-solid {
  padding-left: 0rem;
}

.buttonLightGrey {
  padding: 1.2rem;
  padding-left: 2rem;
  padding-right: 2rem;

  margin-top: 1rem;
  margin-bottom: 1rem;

  border-radius: 40px;
  background-color: #e95057;
}

.buttonLightGrey:hover {
  background-color: #c3464c;
}

.smallRound {
  width: 6rem;
  border-radius: 50%;
  height: 6rem;
  margin-top: -1rem;
}

.roundIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(25, 27, 28);
  height: 16rem;
  width: 16rem;
  border-radius: 100%;
  text-align: center;
}

.coverChannels {
  padding-top: 2rem;

  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  min-height: 55rem;
}

@media screen and (max-width: 850px) {
  .coverChannels {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 575px) {
  #minHeightFilter {
    min-height: 44rem;
  }
}

.channelsHP {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.channelsHP .fa-solid {
  padding-top: 0rem;
  padding-left: 0rem;
}

.sliderChannels {
  overflow: hidden;
  /* Her is the trick !*/

  height: 420px;
  text-align: left;
}

.sliderChannelsInside {
  max-width: 94%;
}

.channelsHP::-webkit-scrollbar {
  overflow: hidden;
}

.coverChannels .alignHorizontal {
  position: relative;
  overflow: hidden;
  max-width: 150rem;
  width: calc(100vw - 6rem);
}

@media screen and (min-width: 850px) {
  .coverChannels .alignHorizontal {
    width: calc(100vw - 14rem);
  }
}

.coverChannels .fa-chevron-left,
.coverChannels .fa-chevron-right {
  padding: 2rem;
  font-size: 3rem;
  cursor: pointer;
  padding-top: 20rem;
}
.coverChannels .fa-chevron-right {
  background-image: linear-gradient(
    to right,
    rgba(76, 88, 102, 0),
    rgba(12, 12, 14, 0.9),
    rgba(12, 12, 14, 1)
  );
  position: absolute;
  height: 220px;
  padding-left: 3rem;
  padding-right: 0rem;
}

.fa-chevron-right {
  right: 0rem;
  z-index: 4;
  cursor: pointer;
}

.fa-chevron-left {
  cursor: pointer;
}

.similarSlide #chevronMobile {
  margin-top: 3rem;
  border-radius: 20px;
  position: absolute;
  padding-right: 1rem;
  padding-left: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.similarSlide {
  max-width: calc(100vw - 14rem);
  position: relative;
}

@media screen and (max-width: 850px) {
  .similarSlide {
    max-width: calc(100vw - 6rem);
  }
}

.sliderChannelsInside {
  height: 45rem;
}

.alignHorizontal .card-body li {
  padding-left: 0rem;
}

.toRight {
  margin-left: auto;
  margin-top: 4.5rem;
}

#darkGrey .twoThird p {
  font-weight: 250;
  font-size: 2rem;
}

.filterSlider {
  overflow: hidden;
  max-width: 100vw;
  margin-left: -6rem;
  margin-right: -4rem;
}

.ulFilter {
  display: flex;
  overflow-x: auto;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.ulFilter li {
  text-align: center;
  padding: 1rem;
}

.ulFilter i {
  padding-left: 6px;
  padding-right: 6px;
}

.ulFilter .active li {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 8px;
  background-color: rgb(89, 83, 95);
}

.ulFilter li:hover {
  background-color: rgb(89, 83, 95);
  border-radius: 8px;
}

.recommendedTopics {
  height: 15rem;
  width: 15rem;
  background-color: white;
  overflow: hidden;
  border-radius: 8px;
  margin: 1rem;
  align-items: center;
}

#pink {
  background-color: #fd9096;
}
#darkBlueI {
  background-color: #0d3b66;
}
#darkPurple {
  background-color: #2e3045;
}
#green {
  background-color: #2e545b;
}

#redI {
  background-color: #ea696b;
}

#lightBlue {
  background-color: #76ceda;
}

#darkRedI {
  background-color: #e74a49;
}

#darkGreen {
  background-color: #2e4552;
}

.recommendedTopics i {
  margin-top: 1rem;
  font-size: 6rem;
  padding-left: 0rem !important;
}

@media screen and (max-width: 1275px) {
  .recommendedTopics {
    height: 11rem;
    width: 11rem;
  }
  .recommendedTopics i {
    margin-top: 0rem;
    font-size: 4rem;
  }
}
@media screen and (max-width: 1008px) {
  .recommendedTopics {
    height: 15rem;
    width: 15rem;
  }
  .recommendedTopics i {
    margin-top: 1rem;
    font-size: 6rem;
  }
}

@media screen and (max-width: 670px) {
  .recommendedTopics {
    height: 10rem;
    width: 11rem;
  }
  .recommendedTopics i {
    margin-top: 0.5rem;
    font-size: 2.5rem;
  }
  .recommendedTopics h3 {
    font-size: 1.8rem;
  }
}

#coverReviews .review {
  max-width: 43rem;
  margin: 1rem;
  padding: 2rem;
  /* height: 20rem;  */
}

p {
  word-break: break-word;
}

#coverReviews p {
  margin-bottom: 0rem;
}

@media screen and (max-width: 850px) {
  #coverReviews .review {
    width: calc(100vw - 12rem);
  }
}

@media screen and (min-width: 500px) {
  #coverReviews .review {
    min-width: 43rem;
  }
}

#coverReviews .column1 {
  width: 100%;
  padding-right: 0rem;
}

#coverReviews .alignHorizontal {
  padding-top: 1rem;
  width: auto;
  max-width: 90vw;
}

#coverReviews .review .fa-solid {
  padding-left: 0rem;
}

#coverReviews p {
  font-weight: 300;
  min-height: 10rem;
}

@media screen and (max-width: 700px) {
  #coverReviews p {
    min-height: 2rem;
    margin-bottom: 2rem;
  }
}

#coverReviews text {
  padding-right: 0.2rem;
  color: rgb(160, 159, 159);
  font-weight: 400;
  vertical-align: 0%;
}

#coverReviews strong {
  margin-top: 1.2rem;
}

#coverReviews .alignHorizontal text {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

#coverReviews .rating {
  padding-bottom: 1.5rem;
}

#coverReviews .row {
  align-items: flex-start;
}

.fadeout {
  height: 6rem;
  width: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(25, 27, 28) 0%,
    rgba(25, 27, 28, 0)
  );
  position: absolute;
  z-index: 1;
  bottom: 32px;
}

.absolute {
  position: relative;
}

/* Images */

img.logo {
  padding-top: 0.9rem;
  max-width: 15rem;
  padding-left: 2rem;
}

@media screen and (max-width: 850px) {
  img.logo {
    padding-left: 0.5rem;
  }
}

img.beta {
  margin-bottom: 0.5rem;
  width: 6rem;
}

img.small {
  /* min-width: 100%;  */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  opacity: 0.22;
  /*width: 50%;  */
}
.smallContainer {
  width: 22rem;
  height: 12rem;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

img.verysmall {
  max-width: 4rem;
  width: 100%;
  margin-left: 0%;
  border-radius: 4px;
}

.crop {
  width: 29rem;
  height: 16rem;
  overflow: hidden;
  position: relative;
}

img.medium {
  min-width: 100%;
  max-width: 120%;
  min-height: 100%;
  max-height: 120%;
  transition: transform 0.5s ease;
  text-align: right;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  opacity: 0.23;
}

img.medium:hover {
  transform: scale(1.05);
}

img.large {
  width: 100%;
  margin-top: 3rem;
  border-radius: 8px;
}

.channelOfWeek img.large {
  margin-bottom: 3rem;
}

@media screen and (max-width: 620px) {
  .channelOfWeek img.large {
    margin-bottom: 0rem;
  }
}

.profileImage {
  border-radius: 60px;
  height: 80px;
  position: absolute;
  z-index: 1;
  place-self: center;
  /*
  margin-left: 10rem;
  margin-top: 4rem; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profileImagePlaylist {
  position: relative;
  height: 90px;
  border-radius: 60px;
  left: 50%;
  transform: translate(-50%);
}

#iconHP {
  height: 8rem;
  margin-top: 4rem;
}

/* Card */

.card {
  /*border: 0.1rem grey solid;*/
  background-color: rgb(25, 27, 28);
  border-radius: 8px;
  margin: 1rem;
  width: 29rem;
  height: 43.5rem;
  overflow: hidden;
  /*background-color: #212121;
  /* box-shadow: 15px 15px 30px #191919, -15px -15px 30px #292929;
  transition: background-color border-radius cubic-bezier(0.075, 0.82, 0.165, 1)
      1s,
    transform cubic-bezier(0.075, 0.82, 0.165, 1) 1s;  */
}

.card:hover {
  /*background-color: rgb(32, 28, 36); */
  background-color: rgb(44, 39, 48);
  /* transform: rgb(44, 39, 48); */
}

.card-body {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.card-body .rating {
  margin-top: 0rem;
  margin-bottom: 1rem;
}
.card-body > * {
  margin-bottom: 0.5rem;
}

.topic {
  background-color: rgb(71, 66, 76);
  color: white;
  font-size: 1.2rem;
  padding: 0.4rem;
  border-radius: 1rem;
  font-weight: 400;
  margin-right: 0.2rem;
  white-space: nowrap;
  margin-top: 3px;
}

.card p {
  font-weight: 350;
  font-size: 13px;
  height: 5.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0rem;
}

.topics {
  display: flex;
  z-index: 4;
  /* -webkit-mask: linear-gradient(to right, #fff 90%, transparent);
  mask: linear-gradient(#fff, transparent);  */
  opacity: 0.9;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 5.25rem;
  overflow: hidden;
}

.topics ul {
  display: flex;
  z-index: 0;
  z-index: -1;
  flex-wrap: wrap;
}

.topics li {
  margin-top: 0rem;
  margin-bottom: 0rem;
  display: inline-flex;
  z-index: -1;
  flex-wrap: wrap;
}

.topics a {
  margin-top: 0.3rem;
  display: flex;
}

.card-body3-product {
  width: 100%;
  font-size: 1.2rem;
  background-color: transparent;
  margin: 0rem;
  border-radius: 0rem;
  padding-top: 0.8rem;
  padding-bottom: 0rem;
  margin-top: 1rem;
  margin-bottom: -1px;

  height: auto;
}

.card-body3-product .oneThird {
  text-align: center;
  vertical-align: top;
  padding-left: 3px;
  padding-right: 3px;
}

.card-body3-product text {
  font-weight: lighter;
  margin-top: 6rem;
}

.card-body3-product .verysmall {
  margin-top: 0.5rem;
  width: 3rem;
}

.card-body text {
  font-size: 1.4rem;
  font-weight: 300;
}

.card-body strong {
  font-size: 1.3rem;
}

#coma ~ #coma::before {
  content: " -  ";
}

.round {
  margin: 8px 4px 9px 0;
  border-radius: 100%;
  display: inline-block;
  height: 10px;
  vertical-align: middle;
  width: 10px;
}

.Medium {
  background-color: #fbbc05;
}

.Beginner {
  background-color: #34a853;
}

.Pro {
  background-color: #fb3640;
}

h3 {
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}

#hoverOpacity:hover {
  opacity: 0.9;
}

/* Rating */

.rating span {
  color: #fb3640;
  font-weight: bold;
}

.rating span:last-child {
  color: grey;
}

.active .rating span:last-child {
  color: white;
}

.divRatings {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  margin: 0%;
  /* border: grey 0.05rem;
  border-style: solid;  */
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  height: 1rem;
}

.ulRating {
  margin-bottom: 0rem !important;
  border-top: solid grey 1px;
  width: 100%;
}

.divRatings i {
  margin-right: 5px;
}

#XPactive {
  font-size: 30px;
  margin-left: 2px;
  display: inline-block;
  color: #fb3640;
  margin-right: 0px;
}

#XPinactive {
  font-size: 30px;
  margin-left: 2px;
  display: inline-block;
  color: grey;
  margin-right: 0px;
}

.ratingXP {
  margin-left: auto;
  margin-right: 0;
  margin-top: -0.5rem;
}

.review .channelsHP .rating {
  padding-left: 0rem;
  padding-bottom: 0rem;
  padding-top: 0.5rem;
}

.topSimilarPlaylist .rating {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}

/* Alert */

.loading {
  display: block !important;
}

.success {
  color: green;
}

.danger {
  color: #fb3640;
}

.alert {
  padding-top: 1rem;
  padding-bottom: 1rem;

  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}

.alert-info {
  color: #fb3640;
  background-color: rgb(12, 12, 14);
}

.col-2 .alert-info {
  margin-top: 1rem;
  margin-left: 4rem;
}

.alert-danger {
  color: #fb3640;
  background-color: rgb(12, 12, 14);
}

.alert-success {
  color: #46fb36;
}

/* Form */

.form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;
  min-width: 28rem;
}

.form > div {
  /* display: flex;  */
  flex-direction: column;
  padding-top: 1rem;
  /* color: #0582ca;  */
}

.form label {
  margin: 1rem 0;
  display: block;
  color: white;
}

.form h1 {
  margin: 1rem 0;
}

.block-review .popup-inner {
  text-align: left;
  padding: 2rem;
  max-width: 60rem;
}

.form hr {
  border-color: rgb(123, 123, 123);
}

#purple {
  background-color: #5865f2;
}

#purple:hover {
  background-color: #6772f3;
}

/* Dropdown */

.dropdown-content {
  display: none;
  right: 0;
  min-width: 16rem;
  padding: 1rem;
  z-index: 1;
  background-color: rgb(25, 27, 28);
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 8px;
}

header .dropdown-content {
  position: absolute;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.show {
  display: block;
}

/* Checkout Steps */

.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}

/* Search */

.search button {
  border-radius: 0 40px 40px 0;
  border-right: none;
  margin-right: 0.5rem;
  margin-left: 0rem;
  background-color: white;
  color: rgb(120, 119, 123);
  height: 35px;
  padding-right: 1.5rem;
}
.search input {
  border-radius: 40px 0 0 40px;
  border-right: none;
  margin-left: 0.5rem;
  margin-right: 0rem;
  width: 26rem;
  transition: width 0.5s;
  outline: none;
}

.search #q {
  height: 35px;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

#menuHam .search input#q {
  padding-top: 0px;
  padding-bottom: 0px;

  height: 4rem;
}
#menuHam .search .primary {
  height: 4rem;
}

.search input:focus {
  width: 28rem;
}

.active {
  font-weight: bold;
  color: white;
}

a.notactive {
  color: grey !important;
  font-weight: normal;
}

.grey {
  color: grey;
}

a.notactive:hover {
  color: white !important;
}

.rating:hover {
  color: white;
}

.rating span:focus {
  color: white;
}

select {
  background-color: rgb(25, 27, 28);
  color: white;
  max-width: 26rem;
  border-radius: 8px;
  font-family: "Fellix", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

select:hover {
  background-color: rgb(25, 27, 28);
  cursor: pointer;
}

.col-2 .loading {
  margin-left: 4rem;
  margin-top: 2rem;
}

.moreFilters {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #42484bbf;
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
}
.moreFilters:hover {
  background-color: #4c5356bf;
}

#scdColor .sortVideos {
  width: max-content;
}

.sortVideos p {
  width: max-content;
  margin-bottom: 0rem;
}

.ulFilter {
  margin-left: 2rem;
}

#userListing .alignVertical {
  align-items: baseline;
}

#userListing .fa-user {
  background-color: #ea696b;
  padding: 5px;
  border-radius: 30px;
  height: 12px;
  width: 12px;
  text-align: center;
  margin-bottom: 2.5rem;
  margin-right: 0.5rem;
}

/* DETAILS */

div.number_programs {
  text-align: right;
  padding-right: 3rem;
  padding-top: 0.5rem;
}

@media screen and (max-width: 575px) {
  div.number_programs {
    text-align: center;
    padding-top: 2rem;

    margin-left: -18rem;
  }
}

#getData text {
  max-width: 200px;
  text-align: center;
}

#getData {
  font-size: 1.6rem;
  min-width: 140px;
}

#getData .giveReview {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

/* FILTERS */

#btnFilter {
  width: 100%;
  text-align: left;
  background-color: transparent;
}

.dropdownFilter {
  background-color: rgb(25, 27, 28);
  border-style: solid;
  border-color: rgb(12, 12, 14);
  padding: 1rem;
  width: 25rem;
}

@media screen and (max-width: 600px) {
  .dropdownFilter {
    width: 80vw;
  }
}

.filterBox {
  display: none;
}

.filterBoxActive {
  padding: 1rem;
}

.dropdownFilter:hover {
  background-color: rgb(44, 39, 48);
}

#arrowTurn {
  animation-name: rotate;
  animation-duration: 0.5s;
  transform: rotate(180deg);
}

.subFilter {
  padding-bottom: 1rem;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

.fa-caret-down {
  float: right;
}

#btnFilter text {
  padding-left: 1rem;
}

a input {
  margin-right: 2rem !important;
  padding-top: -5rem;
}

input[type="checkbox"] {
  /* visibility: hidden;  */
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

.form input[type="text"] {
  width: 90%;
  max-width: 32rem;
  min-width: 22rem;
}

.form input[type="email"] {
  width: 28rem;
}

.form input[type="password"] {
  width: 28rem;
}

.switch {
  --secondary-container: #0582ca3c;
  --primary: #aeaaae;
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.7em;
  height: 1.8em;
  margin-left: 1rem;
  margin-right: 1.5rem;
}

.switch input {
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #313033;
  transition: 0.2s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.2em;
  bottom: 0.2em;
  background-color: #0582ca;
  transition: 0.4s;
}

.switch input:checked + .slider::before {
  background-color: var(--primary);
}

.switch input:checked + .slider {
  background-color: var(--secondary-container);
  box-shadow: 0 0 0px;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--secondary-container);
}

.switch input:checked + .slider:before {
  transform: translateX(1.9em);
}

.logoActive {
  max-width: 10rem;
  max-height: 5rem;
  opacity: 1;
}

.logoNotactive {
  max-width: 10rem;
  max-height: 5rem;
  opacity: 0.5;
}

.Checked {
  background-color: #fb3640;
  margin-right: 1.5rem;
}

.backgroundTable .topicFilter,
.col-1 .topicFilter {
  color: white;
  padding: 0.6rem;
  border-radius: 1rem;
  font-weight: 400;
  margin-right: 0.2rem;
  white-space: nowrap;
  border: solid rgb(71, 66, 76) 0.4px;
}

.topicFilter:hover {
  background-color: rgb(71, 66, 76, 0.5);
}

.topicFilter input[type="checkbox"] {
  display: none;
}

.active .topicFilter {
  background-color: rgb(89, 83, 95);
  font-weight: bold;
}

.filterBubbles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 220px;
}

@media screen and (max-width: 600px) {
  .filterBubbles {
    max-width: 80vw;
  }
}

/* PAGINATION */

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 1rem;
  gap: 15px;
}

.page-item:hover {
  cursor: pointer;
}

.active-pagination {
  background-color: #fb3640;
  border-radius: 2rem;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
}

/* Program Page */

.col1-ProductDescription {
  text-align: left;
  max-width: 70rem;
  width: 100%;
}

div[class="row top"] {
  justify-content: center;
  display: flex;
}

@media screen and (min-width: 808px) {
  .productPage div[class="row top"] {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 30px;
  }
}

.productPage {
  margin: auto;
  max-width: 150rem;
}

.col2-ProductDescription {
  max-width: 80rem;
}

.col2-ProductDescription .primary {
  margin-left: auto;
  margin-right: 2rem;
}

.col2-ProductDescription .buttondelete {
  margin-left: auto;
  margin-right: 2rem;
}

.block-review {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.ninetyPercent {
  width: 100%;
  min-width: 90%;
}

.review {
  background-color: #191b1c;
  padding: 2rem 0 2rem 2rem;
  width: calc(100vw - 10rem);
  max-width: 100rem;
  border-radius: 8px;
  overflow: hidden;
  text-align: left;
  border: 0.4px solid grey;
}

@media screen and (max-width: 850px) {
  .review {
    width: calc(100vw - 8rem);
  }
}

.review .rating {
  display: inline-block;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.review .moreFilters {
  background-color: transparent;
}

.review .moreFilters:hover {
  background-color: #4c5356bf;
}

.column1 {
  width: 60%;
}

@media screen and (max-width: 880px) {
  .column1 {
    width: 100%;
    padding-right: 2rem;
    min-width: 10rem;
  }
}

.column2 {
  width: 30rem;
  padding-left: 2rem;
  border-left: solid grey;
  border-width: 0.1px;
  margin-right: 2rem;
  max-height: 10rem;
}

@media screen and (max-width: 880px) {
  .column2 {
    border-width: 0px;
    padding-left: 0rem;
  }
}

.column2 .topics {
  overflow: visible;
  margin-top: 0.5rem;
}

.column2 li {
  margin: 0rem;
}

.column2 input {
  width: 0rem;
}

.review .row {
  align-items: initial;
}

.review .topic {
  font-size: 1.6rem;
}

.review .topics {
  max-height: 20rem;
}

.moreFilters .fa-solid {
  padding-top: 0rem;
}

.moreFilters {
  font-size: 1.4rem;
}

.alignHorizontal {
  display: flex;
}

.col2-ProductDescription .alignHorizontal {
  align-items: center;
}

.alignVertical {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  min-width: 30rem;
}

.alignHorizontal li {
  padding-left: 1rem;
}

.alignHorizontal h2 {
  vertical-align: middle;
  padding-right: 2rem;
  padding-top: 1rem;
}

.profileUser .popup h2 {
  text-align: center;
}

h5 {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0rem;
  color: rgb(147, 147, 147);
}

.col2-ProductDescription .ratingXP {
  display: inline-block;
  position: relative;
  float: right;
}

.otherRating {
  padding-left: 3rem;
}

.selectRating {
  /* display: inline-block;  */
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  padding-top: 0rem !important;
  max-width: 64rem;
  min-width: 26rem;
}

.selectRating li {
  width: 13rem;
}

.selectRating #Inaccurateinformation {
  width: 21rem;
}

.card-body3 {
  width: 100%;
  max-width: 50rem;
  margin: 0rem;
  margin-top: 4rem;
  margin-bottom: 1rem;
  height: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
  align-items: center;
  text-align: justify;
  text-align-last: center;
}

.card-body3:hover {
  background-color: rgb(25, 27, 28);
}
.card-body3 .oneThird {
  text-align: center;
  vertical-align: middle;
  min-width: 10rem;
}

.col2-ProductDescription .topic {
  font-size: 1.6rem;
}

.col2-ProductDescription .topics {
  max-height: 10rem;
  max-width: 55rem;
}

.thumbnails {
  margin: 0rem 1rem 0rem 1rem;
  width: 190px;
  position: relative;
}

.videoThumbnails {
  padding: 1rem;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .videoThumbnails {
    padding: 1rem 0rem 1rem 0rem;
  }
}

.greyButton {
  background-color: rgb(71, 66, 76);
}

.greyButton:hover {
  background-color: rgb(71, 66, 76);
  cursor: default;
}

.review .thumbnails {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.videoThumbnails a {
  max-width: 39rem;
}

.sortVideos {
  height: 4rem;
  border: solid grey 1px;
  background-color: rgb(71, 66, 76, 0);
  margin-left: 2rem;
}

.sortVideos:hover {
  background-color: rgb(255, 255, 255, 0.2);
}

.sortVideos + .sortVideos {
  margin-left: 1rem;
}

#sortVideosOn {
  background-color: white;
  color: rgb(12, 12, 14);
}

@media screen and (max-width: 808px) {
  .sortVideos {
    width: 9rem;
  }
}

#totalWidth {
  width: 100%;
}

.likeReview {
  margin-left: auto;
  margin-right: 3rem;
  cursor: pointer;
  font-size: 1.7rem;
}

.likeReview #pinkFont {
  color: #fd9096;
}

#coverReviews .review .fa-heart {
  padding-left: 0rem;
  padding-top: 0rem;
}

.clickable:hover {
  color: rgb(255, 255, 255, 0.7);
}

.VS strong {
  font-size: 2.5rem;
}

.VS {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  width: 4.95rem;
  margin-top: 16rem;
  margin-bottom: auto;
  border-radius: 8rem;
  background-color: grey;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vl {
  border-left: 1px solid rgb(50, 54, 59);
  height: 320px;
  margin: auto;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 4rem;
}

@media screen and (max-width: 1020px) {
  .VS {
    margin-top: auto;
  }

  .vl {
    border-top: 1px solid rgb(50, 54, 59);
    border-left: transparent;
    width: 54rem;
    max-width: 70vw;
    height: 1px;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.thumbnailOnChannelPage {
  width: 100%;
  height: calc(45vw);
  max-height: 40rem;
  object-fit: cover;
  object-position: center;
  margin-top: 3rem;
  border-radius: 8px;
}

/* GIVE RATING */

input[type="radio"] {
  display: none;
}

.selectRating label {
  font-size: 2rem;
  color: #fb3640;
  cursor: pointer;
}

/* FIND SIMILAR SECTION */

.centered {
  text-align: center;
}

.colSimilar {
  display: inline-block;
  width: 34rem;
  /*border: solid grey 1px;
  border-radius: 3rem; */
  border-radius: 8px;
  background: #162e3c;
  padding-bottom: 2rem;
  text-align: left;
  vertical-align: top;
}

.colSimilar2 {
  display: inline-block;
  width: 49.9%;
  text-align: left;
}

.colSimilar3 {
  display: inline-block;
  width: 49.9%;
  border-left: solid black 1px;
  text-align: left;
}

.coltwothird {
  display: inline-block;
  width: 100rem;
  /*border: solid grey 1px;
  border-radius: 3rem; */
  border-radius: 8px;
  background: #162e3c;
  min-height: 72rem;
  margin-left: 2rem;
}
.colSimilar h1,
.colSimilar h2,
.coltwothird h1,
.coltwothird h2 {
  text-align: center;
}

.dataResult {
  margin-top: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-height: 15rem;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  width: 28rem;
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  text-align: left;
}

.dataResult a {
  color: black;
  padding-left: 1rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  font-weight: 350;
  font-size: 14px;
  display: block;
}

.dataResult a:hover {
  background-color: #c0c0c0;
}

.searchSimilar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchSimilarIcon {
  display: inline;
  background-color: white;
  color: rgb(120, 119, 123);
  border-radius: 0px 8px 8px 0px;
  padding: 1rem;
  margin-left: -1rem;
  cursor: pointer;
}
.fa-xmark {
  cursor: pointer;
}

.searchInputs input {
  width: 24rem;
  margin-right: 0;
  outline: none;
  border-radius: 8px;
}

.rankingSimilar {
  background-color: #f8454e;
  padding: 0rem 1rem 0rem 1rem;
  border-radius: 8px 0px 8px 0px;
  position: absolute;
  z-index: 1;
}

.coltwothird h3 {
  padding-bottom: 4.5rem;
}

.reviewSubmit #comment {
  width: 80%;
  max-width: 80rem;
}

.reviewSubmit .alignVertical {
  min-width: 18rem;
  align-items: baseline;
}

@media screen and (max-width: 600px) {
  .reviewSubmit .alignHorizontal {
    display: grid;
  }
}

.giveReview {
  font-weight: bold;
  background-color: #0693e3;
  padding: 3px 10px 3px 10px;
  border-radius: 22px;
}

table {
  margin: auto;
  /* border-spacing: 16px; */
  border-collapse: collapse;
}

.tdCentral {
  padding-right: 0rem;
  text-align: center;
}

.tdCentral .small {
  border-radius: 8px;
}

td {
  padding-right: 0rem;
}

@media screen and (max-width: 500px) {
  .td {
    padding: 0rem;
  }
}

tbody tr {
  border-bottom: solid rgb(46, 48, 52) 1px;
}

td .logoActive {
  max-width: 9rem;
}

.backgroundTable {
  background-color: rgb(25, 27, 28);
  margin: 2rem;
  border-radius: 8px;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
}

thead {
  height: 5rem;
}

tbody h1 {
  padding-top: 0rem;
}

.fiftyPercent .card-body2 {
  margin: auto;
}
/* FOOTER */

footer {
  grid-area: footer;
  background-color: rgb(12, 12, 14);
  color: white;
  vertical-align: top;
  min-height: 90rem;
  width: 100%;
}
@media screen and (max-width: 700px) {
  footer {
    min-height: 130rem;
  }
}

.social {
  padding: 2rem;
  max-width: 19rem;
  text-align: center;
}

.social .fa-brands {
  padding: 1rem;
  font-size: 2rem;
}

.textFooter {
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.listFooter {
  padding: 2rem;
  padding-left: 6rem;
  max-width: 30rem;
  vertical-align: baseline;
}

.listFooter a {
  font-weight: 300;
}

.listFooter text {
  font-weight: 700;
}

@media screen and (max-width: 400px) {
  .listFooter {
    padding-left: 2rem;
  }
}

#topAlign {
  align-items: baseline;
}

@media screen and (max-width: 1000px) {
  #removeMobile {
    display: none;
  }
}

#buyPizza {
  margin: 3rem;
}

@media screen and (max-width: 7000px) {
  #buyPizza {
    margin-left: 0rem;
    margin-right: 0rem;
    max-width: 90%;
    min-width: 22rem;
  }
}

.leftAlignFlex {
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  margin-left: auto;
  margin-right: auto;
}

/* HP BROWSE VIDEOS SECTION */

.backgroundTableHP {
  background-color: rgb(25, 27, 28);
  margin: auto;
  border-radius: 8px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  max-width: 150rem;
}

.backgroundTableHP table {
  min-height: 66rem;
}

.backgroundTableHP .videoThumbnailsBrowse {
  margin-top: 3rem;
  margin-left: 1rem;
}

.productPage .videoThumbnailsBrowse {
  padding: 2rem;
}

@media screen and (max-width: 500px) {
  .productPage .videoThumbnailsBrowse {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.backgroundTableHP .tdFilter {
  vertical-align: top;
  text-align: left;
  padding-top: 1rem;
  padding-left: 3rem;
  width: 22rem;
}

.backgroundTableHP tbody tr {
  border-bottom: 0px;
}

.filterHP .topicFilter {
  color: white;
  padding: 0.6rem;
  border-radius: 1rem;
  font-weight: 400;
  margin-right: 0.2rem;
  white-space: nowrap;
  cursor: pointer;
}

.filterHP .active a {
  font-weight: bold;
}

.filterHP li {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.buttonLightGrey i {
  margin-left: 0rem;
  margin-right: 1rem;
}

/* MY PLAYLIST  */

.colPlaylist {
  flex: 1 1 30rem;
  /* margin-right: 3rem; */
  /*text-align: right;*/
  /* max-width: 80rem;  */
}

.colPlaylist .backgroundTable {
  margin: 0rem;
  max-width: 120rem;
}

.colPlaylist .alignVertical {
  min-width: 20rem;
}

.card-body2 {
  margin-top: 8.5rem;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  max-height: 17rem;
  text-align: center;
  width: 22rem;
}
.card-body2 p {
  font-size: 1.6rem;
  margin-bottom: 0rem;
  /*height: 4rem;*/
}

.card-body2:hover {
  background-color: rgb(25, 27, 28);
}

.fa-paper-plane {
  padding-bottom: 2rem;
  font-size: 3rem;
}

@media screen and (max-width: 1087px) {
  .colPlaylist table thead {
    display: none;
  }

  .colPlaylist table,
  .colPlaylist tbody,
  .colPlaylist tr,
  .colPlaylist td {
    display: block;
  }

  .colPlaylist .small {
    max-width: 400px;
    width: 100%;
  }

  .colPlaylist #namePlaylist {
    min-width: 200px;
  }

  .colPlaylist .alignHorizontal {
    align-items: center;
    justify-content: center;
  }

  .colPlaylist .alignHorizontal .videoThumbnailsBrowse {
    margin-left: auto;
    margin-right: auto;
  }

  .colPlaylist .topics {
    align-items: center;
    justify-content: center;
  }
  .colPlaylist .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.colPlaylist #namePlaylist {
  text-align: center;
}

.colPlaylist td {
  margin: 1rem 1rem 1rem 1rem;
}

@media screen and (max-width: 730px) {
  .colPlaylist .alignHorizontal {
    display: block;
  }
}

.topSimilarPlaylist {
  margin-left: 2rem;
  background-color: rgb(25, 27, 28);
  padding: 1rem;
  border-radius: 8px;
  max-width: 120rem;
}

.topSimilarPlaylist h3 {
  padding: 1rem;
}
@media screen and (min-width: 1368px) {
  #similarPlaylist {
    width: 30rem;
  }
}

@media screen and (max-width: 1368px) {
  #similarPlaylist {
    margin-left: 0rem;
    margin-right: auto;
  }

  .topSimilarPlaylist {
    margin-left: 0rem;
  }
}

.colPlaylist .sortVideos {
  width: 100%;
  max-width: 36rem;
  margin-top: 2.2rem;
  margin-left: auto;
}
/*----- POP UP ------*/

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  z-index: 4;
}

.popup-inner {
  position: relative;
  padding: 10px 40px 30px 40px;
  width: 80%;
  max-width: 500px;
  background-color: #162e3c;
  border-radius: 0.8rem;
  text-align: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .popup-inner {
    width: 90%;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.popup-inner p {
  text-align: center;
}

.popup-inner .fa-xmark {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 2rem;
}

.popup-inner h1,
.popup-inner h2 {
  text-align: center;
}

.img-popup {
  width: 100%;
  border-radius: 8px;
}

.popup-inner .imageSubmitted {
  width: 35rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.imageSubmitted {
  margin-left: auto;
  margin-right: auto;
}

.colPlaylist .videoThumbnails {
  padding: 1rem;
}

td .topics {
  max-height: 12.2rem;
}

.fa-circle-xmark {
  color: red;
}

#smallPopUp {
  max-width: 36rem;
  text-align: left;
  padding: 16px 30px 20px 40px;
}

/* SUBMIT PAGE */

.pictureCoverSubmit {
  border-radius: 12px;
  width: 100%;
}

/* Discover Videos PAGE */

.bigIcon {
  font-size: 40px;
}
.videoThumbnails a {
  vertical-align: middle;
  padding-top: 0.5rem;
}

#rotate {
  animation-name: rotateLoad;
  animation-duration: 0.5s;
  transform: rotate(180deg);
}

@keyframes rotateLoad {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

.picturePopUp {
  max-width: 25rem;
  margin: 1rem;
  width: 80%;
  opacity: 0.7;
  margin-bottom: 2rem;
}

/* SLIDER */

.range-slider {
  /* display: flex;  */
  flex-flow: row wrap;
  align-items: center;
  width: 38rem;
  background-color: rgb(25, 27, 28);
  border-radius: 8px;
  padding: 1.9rem 0rem 2.2rem 2rem;
}

.range-slider label {
  font-size: 13px;
}

.range-group {
  position: relative;
  /*flex: 0 0 100%;  */
  margin-left: 0rem;
  margin-right: auto;
}

.range-input {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  -webkit-appearance: none;
  width: 22rem;
  border-bottom: 0;
  background-color: rgb(25, 27, 28);
  padding-left: 4rem;
  padding-right: 2rem;
}

.range-input:focus {
  outline: 0;
}

.range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animation: 0.2s;
  background: #fb3640;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

.range-input::-webkit-slider-thumb {
  z-index: 2;
  position: relative;

  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #fb3640;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

.range-input::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;

  animation: 0.2s;
  background: #3faffa;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

.range-input::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #3faffa;
  cursor: pointer;
}

.range-input::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animation: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.range-input::-ms-fill-lower,
.range-input::-ms-fill-upper {
  background: #3faffa;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

.range-input::-ms-thumb {
  z-index: 2;
  position: relative;

  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #3faffa;
  cursor: pointer;
}

.range-slider {
  margin-left: auto;
  margin-right: 0;
}

/*   BROWSE SCREEN  */

.filterVideos {
  background-color: rgb(25, 27, 28);
  border-radius: 8px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.browseSort {
  background-color: rgb(62, 58, 67);
  margin: 1.5rem;
  margin-top: 1rem;
  border-radius: 60px;
  padding: 0.5rem 2rem 0.7rem 2rem;
  width: 110px;
}

@media screen and (max-width: 615px) {
  .filterVideos {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .browseSort {
    width: 67px;
    padding: 0.4rem 0.6rem 1.2rem 0.6rem;
    margin: 1rem 0.9rem 1rem 0.9rem;
    border-radius: 20px;
  }
}

.sortOn {
  background-color: #fb3640;
}

.videoThumbnailsBrowse {
  padding: 0rem 1rem 1rem 1rem;
  max-width: 324px;
  min-height: 225px;
  text-align: left;
  border-radius: 40px;
  margin-left: auto;
  margin-right: auto;
}

.colPlaylist .videoThumbnailsBrowse {
  padding: 2rem 1rem 2rem 1rem;
}

.videoThumbnailsBrowseClicked {
  padding: 0rem 1rem 1rem 1rem;
  max-width: 440px;
  min-height: 280px;
  text-align: left;
  border-radius: 8px;
  position: fixed;
  background-color: rgb(12, 12, 14, 0.96);
  z-index: 3;
  padding: 2rem;
  bottom: 2rem;
  right: 2rem;
}

@media screen and (max-width: 615px) {
  .videoThumbnailsBrowseClicked {
    padding: 0rem 0rem 1rem 0rem;
    bottom: 1rem;
    right: 0rem;
    width: 382x;
    max-width: 382px;
    min-height: auto;
  }
  .videoThumbnailsBrowseClicked a {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

#crossStopVideo {
  position: absolute;
  top: -1.5rem;
  right: 2rem;
  background-color: rgb(12, 12, 14, 0.96);
  padding: 1rem 1rem 0rem 1rem;
  border-radius: 8px;
}
@media screen and (max-width: 615px) {
  #crossStopVideo {
    position: absolute;
    top: -3.8rem;
    right: 1rem;
    background-color: rgb(12, 12, 14, 0.96);
    padding: 1rem 1rem 0.5rem 1rem;
    border-radius: 8px;
    font-size: 25px;
  }
}

.relative {
  padding: 0rem 1rem 1rem 1rem;
  width: 324px;
  min-height: 225px;
}

.videoThumbnailsBrowse .alignVertical,
.videoThumbnails .alignVertical,
.videoThumbnailsBrowseClicked .alignVertical {
  align-items: baseline;
}

.videoThumbnailsBrowse a text,
.videoThumbnails a text,
.videoThumbnailsBrowseClicked a text {
  color: rgb(170, 170, 170);
  font-weight: 400;
  font-size: 13px;
}

.videoThumbnailsBrowse a,
.videoThumbnailsBrowseClicked a {
  padding-top: 0.3rem;
  cursor: pointer;
}

iframe {
  border-radius: 8px;
}

.fa-arrows-rotate {
  transform: rotate(0deg);
  font-size: 2rem;
}

.fa-arrows-rotate.open {
  transform: rotate(360deg);
  transition: transform 0.5s linear;
}

#paddingTop {
  margin-top: 2rem;
}

@media screen and (max-width: 1100px) {
  .backgroundTable {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.videoThumbnailsBrowse iframe {
  margin-bottom: 6px;
}

@media screen and (max-width: 600px) {
  .fifty {
    width: 40%;
  }
}

.thumbnailContainer {
  width: 324px;
  height: 183px;
  overflow: hidden;
  vertical-align: center;
  border-radius: 8px;
  vertical-align: middle;
  position: relative;
}

img.browseMedium {
  margin: 0;
  position: absolute;
  top: -16%;
  width: 100%;
  cursor: pointer;
}

/* .thumbnailContainer .fa-plus {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 30px;
  padding: 2px 2px 1px 2px;
  border: solid white 1px;
  background-color: rgb(12, 12, 14, 0.6);
}  */

.thumbnailHover {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  background-color: black;
  opacity: 0;
}

.thumbnailHover:hover {
  opacity: 0.5;
}

.thumbnailHover .fa-play {
  font-size: 5rem;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center;
  height: 100%;
}

.rateVideo {
  position: absolute;
  font-size: 2.5rem;
  top: 1rem;
  right: 0rem;
  width: 9rem;
  display: flex;
}

.rateVideo i {
  margin-left: 1rem;
}

@media screen and (max-width: 715px) {
  .rateVideo i {
    margin-right: 2rem;
    margin-left: 0rem;
  }
}

.rateVideo .fa-thumbs-up:hover {
  color: #00e36a;
}

.rateVideo .fa-thumbs-up:active {
  color: yellow;
}

.rateVideo .fa-thumbs-down:hover {
  color: #fb3640;
}

.rateVideo .fa-thumbs-down:active {
  color: yellow;
}

#toPlay:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
}

.tdCentral .center {
  align-items: baseline;
}

.level-req {
  margin-left: -10%;
}

.dropdown-content-topic {
  display: none;
  right: 0;
  min-width: 26rem;
  padding: 1rem;
  z-index: 1;
  background-color: rgb(62, 58, 67);
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
}

.dropdown-content-topic {
  position: absolute;
}

.dropdown-topic:hover .dropdown-content-topic {
  display: block;
}

.dropdown-topic {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.buttonBrowseMore {
  border-radius: 30px;
  padding: 1.5rem;
}

.durationInfo {
  background-color: rgb(0, 0, 0, 0.8);
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 4px 4px 4px 4px;
  position: absolute;
  z-index: 1;
  bottom: 4px;
  right: 4px;
}

#moreSpaceUp {
  margin-top: 5px;
}

.centeredBrowse {
  padding-top: 4rem;
  align-content: center;
  width: 130rem;
}

.tdCentral .alignHorizontal {
  justify-content: center;
}
.tdCentral .alignHorizontal .fifty {
  text-align: center;
  width: 140px;
}

.buttonTransparent {
  border: 0.5px solid white;
  border-radius: 50px;
  max-width: 16rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
}

#buttonTopicsFilter {
  background-color: rgb(0, 0, 0, 0);
  border: 0.5px solid white;
  border-radius: 50px;
  width: 16rem;
}

.soon {
  background-color: #ee9598;
  border-radius: 8px;
  padding: 2rem;
  margin: 1rem;
}

/* NEW SLIDER */

.container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.container2 input {
  padding: 0rem;
}

.slider2 {
  position: relative;
  width: 200px;
}

.slider__track2,
.slider__range2,
.slider__left-value2,
.slider__right-value2 {
  position: absolute;
}

.slider__track2,
.slider__range2 {
  border-radius: 3px;
  height: 5px;
}

.slider__track2 {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range2 {
  background-color: #fb3640;
  z-index: 2;
}

.slider__left-value2,
.slider__right-value2 {
  color: #dee2e6;
  margin-top: 20px;
}

.slider__left-value2 {
  left: 6px;
}

.slider__right-value2 {
  right: -4px;
}

/* Removing the default appearance */

.thumb2,
.thumb2::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb2 {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--left2 {
  z-index: 3;
}

.thumb--right2 {
  z-index: 4;
}

/* For Chrome browsers */
.thumb2::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb2::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* LOGIN */

.error {
  color: #fb3640;
}

.googleIcon {
  height: 2.5rem;
  margin-right: 1rem;
  margin-left: 2rem;
}

.googleConnect {
  background-color: rgb(255, 255, 255);
  color: rgb(74, 74, 74);
  margin-top: 2rem;
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  text-align: center;
  padding-right: 3.5rem;
  min-width: 26rem;
}
.googleConnect:hover {
  background-color: rgb(244, 244, 244);
}

.signUpButton {
  background-color: white;
  color: black;
  border-radius: 50px;
  height: 4.5rem;
  width: 80px;
}

.signUpButton:hover {
  background-color: rgb(238, 238, 238);
}

.noHover + .noHover {
  padding-left: 0rem;
}

#menuHam .signUpButton {
  margin-right: 2rem;
}

#uploadPicture {
  color: white;
  background-color: #4c5866;
  border-radius: 10rem;
}

/* USER SCREEN */

.avatarPictureMedium {
  border-radius: 10rem;
  object-fit: cover;
  width: 12rem;
  height: 12rem;
}

.avatarPictureSmall {
  border-radius: 10rem;
  object-fit: cover;
  width: 6rem;
  height: 6rem;
  margin: 0px 8px 0px 8px !important;
}

#imageOnProfile {
  margin-left: auto;
  margin-right: auto;
}

.profileRound {
  background-color: #ea696b;
  height: 9rem;
  width: 9rem;
  border-radius: 100%;
  margin-right: auto;
  text-align: center;
  margin-left: 0rem;
}
.profileRound .fa-solid {
  font-size: 5.5rem;
  color: white;
  margin: auto;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 1.5rem;
}

#profile {
  align-items: center;
  max-width: 60rem;
  min-width: 14rem;
}

#userInfo {
  min-width: 20rem;
  margin-left: auto;
  border: solid rgb(71, 66, 76) 0.4px;
  border-radius: 8px;
  padding: 0rem 0rem 1.5rem 2rem;
  margin-top: auto;
  margin-bottom: auto;
  align-items: left;
}

@media screen and (max-width: 555px) {
  #userInfo {
    margin-right: auto;
  }
}

.profileUser .space {
  padding: 0.5rem;
}

#alignText {
  align-items: baseline;
}

.profileUser #userInfo h3 {
  margin: 0rem;
}

.profileUser #userInfo .alignHorizontal i {
  align-self: center;
  padding: 0rem;
  padding-left: 0.2rem;
  padding-top: 0.2rem;
  margin-right: -0.3rem;
}

.profileUser .alignVertical {
  align-items: left !important;
}

.profileUser .review .rating {
  padding-top: 1rem;
  padding-left: 0rem;
}

@media screen and (max-width: 850px) {
  .profileUser .sortVideos {
    width: max-content;
  }
  /*
  .profileUser #other {
    min-width: 13rem;
  }  */
}

#toRight {
  flex-wrap: wrap;
}

.profileUser #toRight {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.rightAlign {
  margin-top: 3.2rem;
  margin-left: auto;
  margin-right: 0rem;
}

.marginAuto {
  margin-top: 3.2rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 640px) {
  .rightAlign {
    margin-left: 0rem;
    margin-right: auto;
  }

  #toRight h2 {
    margin-bottom: 0rem;
  }

  .marginAuto {
    margin-left: auto;
    margin-right: auto;
  }
}

/* PROFILE PAGE */

#profileDescription p {
  min-height: 3rem;
  margin-bottom: 1rem;
  max-width: 50rem;
}

@media screen and (max-width: 800px) {
  #profileDescription {
    margin-left: auto;
    margin-right: auto;
  }
  #profileDescription h2 {
    padding-right: 0rem;
  }
}

#profileDescription h2 {
  margin: 0rem;
  margin-right: auto;
}

#profile {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: auto;
}

#profileDescription .editButton {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  margin-bottom: 0rem;
  margin-top: 1rem;
}

@media screen and (max-width: 500px) {
  #profileDescription .editButton {
    padding-left: 1rem;
  }
}

/*
@media screen and (max-width: 380px) {
  #profileDescription .rightButton {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  #blueButton {
  }
}  */

#transparentButton {
  /*  background-color: #0d3b66;  */
  border: solid rgb(71, 66, 76) 0.4px;
  background-color: rgba(255, 255, 255, 0) !important;
  margin-bottom: 0rem;
  margin-top: auto;
}

#transparentButton:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

@media screen and (max-width: 920px) {
  #userInfo {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }
}

#profileDescription .twitterColor {
  color: #00acee;
}

#profileDescription .youTubeColor {
  color: #ff0000;
}

/* TOP REVIEWERS PAGE */

#topReviewers .row {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

#topReviewers li {
}

#topReviewers .medal {
  width: 4rem;
}

.topReviewer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#topReviewers li:hover {
}

#coverReviews .topReviewer strong {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

@media screen and (max-width: 850px) {
  #coverReviews .topReviewer text {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}

@media screen and (max-width: 850px) {
  #coverReviews .topReviewer .alignVertical {
    width: 100% !important;
    margin-bottom: 2rem;
  }

  #coverReviews .topReviewer text {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  #coverReviews .topReviewer .avatarPictureTopContributor {
    margin-left: auto;
    margin-right: auto;
    padding-right: 3.4rem;
    margin-bottom: 2rem !important;
  }

  .rankingTopContributor {
    margin-top: 0rem;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 420px) {
  #coverReviews .topReviewer text {
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

#coverReviews .topReviewer .alignVertical {
  width: 25rem;
  min-width: 25rem;
}

#coverReviews .topReviewer .fa-user {
  margin-right: 1rem;
  margin-left: 1rem;
}

.rankingTopContributor {
  width: 4rem;
  font-size: 3rem;
}

@media screen and (min-width: 849px) {
  #contributor,
  #contributor1,
  #contributor2,
  #contributor3 {
    max-width: calc(100vw - 20rem) !important;
  }
}

#contributor1 {
  background: rgb(12, 12, 14);
  background: linear-gradient(
    350deg,
    rgba(12, 12, 14, 1) 75%,
    rgba(255, 212, 34, 1) 100%
  );
}

#contributor2 {
  background: rgb(12, 12, 14);
  background: linear-gradient(
    350deg,
    rgba(12, 12, 14, 1) 75%,
    rgba(228, 211, 175, 1) 100%
  );
}

#contributor3 {
  background: rgb(12, 12, 14);
  background: linear-gradient(
    350deg,
    rgba(12, 12, 14, 1) 75%,
    rgba(207, 139, 0, 1) 100%
  );
}

/* LIST PAGES */

.createListPage form {
  max-width: 100%;
}

.createListPage .form > div {
  flex-direction: row !important;
}

.createListPage label {
  font-weight: bold;
}

.createListPage text {
  margin-top: auto;
  margin-bottom: auto;
}

.createListPage .channelItemList {
  background-color: #ececec;
  color: #000;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1px;
  max-width: 40rem;
}
.createListPage li {
  padding: 2px;
  cursor: pointer;
}

.createListPage img {
  margin-top: auto;
  margin-bottom: auto;
}

.createListPage .fa-xmark {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 1rem;
  font-size: 2.5rem;
  padding-left: 15px;
}

.createListPage .orderNumber {
  font-size: 3rem;
  font-weight: bold;
  padding: 5px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.createListPage .fa-bars {
  font-size: 2rem;
}

.channelItemList li {
  height: 6rem;
  cursor: pointer;
}

.channelSuggestions {
  background-color: #2f3339;
  padding: 2rem;
  padding-top: 1rem;

  border-radius: 8px;
  max-width: 36rem;
}

/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  box-shadow: 0 0 20px rgba(9, 117, 241, 0.8);
  border: 2px solid #0974f1;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  left: 0.2em;
  bottom: 0.2em;
  /* background-color: white;  */
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.switch input:checked + .slider {
  border: 2px solid #414141;
}

.switch input:checked + .slider:before {
  transform: translateX(1.5em);
}

#filterCentered {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.ulFilter::-webkit-scrollbar {
  display: none;
}

.ulFilter {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.creatorListItem {
  background-color: #2f3339;
  padding: 3rem;
  margin: 2rem;
  border-radius: 8px;
  display: flex;
  width: calc(100vw - 14rem);
  min-width: 20rem;
  max-width: 50rem;
}

.creatorListItem .alignVertical {
  align-items: baseline;
  width: 100%;
  min-width: 20rem;
}

.creatorListItem .alignHorizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.creatorListItem h2 {
  font-size: 2.8rem;
  margin: 0rem;
  margin-top: 1.5rem;
}

.reallySmallRound {
  width: 4rem;
  border-radius: 50%;
  height: 4rem;
  margin: 0rem !important;
}

.image-container {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.image-container img {
  display: inline-block;
  margin-top: 0rem;
  max-width: 100%;
}

.image-fade {
  position: absolute;
  right: 0;
  height: 100%;
  max-height: 8rem;
  width: 70px;
  background: linear-gradient(to left, #2f3339 0%, transparent 100%);
  top: 0;
}

.image-container img:not(:first-child) {
  margin-left: 5px;
}

.buttonLightPink {
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  background-color: #c3464c;
  border-radius: 4rem;
}

.buttonLightPink:hover {
  background-color: #ee9598;
}

@media screen and (max-width: 808px) {
  #listsPage .sortVideos {
    width: auto;
  }
}

@media screen and (max-width: 808px) {
  .creatorListItem {
    margin-left: 0rem;
    margin-right: 0rem;
    width: calc(100vw - 10rem);
  }

  .channelElement {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media screen and (max-width: 680px) {
  .channelElement .alignVertical {
    min-width: 15rem;
  }

  .channelElement .videoThumbnailsBrowse {
    padding: 0rem;
    min-height: auto;
    margin-bottom: 1rem;
  }

  .thumbnailWrapper {
    width: 100%;
  }
}

#grey {
  color: rgb(160, 159, 159);
}

#listPage .alignHorizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#listPage .image-fade {
  background: linear-gradient(to left, rgb(12, 12, 14) 0%, transparent 100%);
}

.channelElement {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #2f3339;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 1rem;
  min-width: 20rem;
  max-width: calc(100vw - 8rem);
}

@media screen and (max-width: 673px) {
  .channelElement {
    width: calc(100vw - 6rem);
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .channelElement .videoThumbnailsBrowse {
    margin-top: 2rem !important;
  }

  .thumbnailContainer {
    width: 100% !important;
    height: auto;
    aspect-ratio: 1.8 / 1 !important;
  }
  .videoThumbnailsBrowse {
    width: 100%;
  }
}

#listPage .channelElement h3 {
  font-size: 2.8rem;
  margin: 0rem;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  max-width: 30rem;
  text-align: center;
}

#listPage .channelElement .smallRound {
  display: inline-block;
  margin-top: 0rem;
  margin-left: auto;
  margin-right: auto;

  height: 8rem;
  width: 8rem;
}

#listPage .channelElement a {
  margin-left: auto;
  margin-right: auto;
}

#listPage h2 {
  font-size: 3.5rem;
  text-align: center;
}

#red {
  background-color: #fb3640;
  font-size: 1.3rem;
}

#listPage .channelElement a:not(:first-child) {
  margin-left: 1rem;
}

#greyBG {
  background-color: grey;
  font-size: 1.3rem;
}

@media screen and (max-width: 430px) {
  #listPage .channelElement .alignVertical {
    width: 100%;
  }
}

.creatorListPicture {
  max-width: 100%;
}

@media screen and (max-width: 850px) {
  .creatorListPicture {
    max-width: 60%;
    margin-top: 1rem;
  }
}

.pinkCover {
  background-color: #ea696b;
}

.columnForm {
  width: 100%;
}

@media screen and (min-width: 820px) {
  .columnForm {
    margin-left: 2rem;
    margin-right: 2rem;
    width: 45%;
    max-width: 55rem;
  }
}

.createListPage strong {
  margin-top: auto;
  margin-bottom: auto;
}

.form2 {
  padding-right: 2rem;
  padding-left: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;
  min-width: 28rem;
  display: block;
  margin-top: 0em;
}

.form2 > div {
  flex-direction: column;
  padding-top: 1rem;
}

.form2 hr {
  border-color: rgb(123, 123, 123);
}

.form2 input[type="text"] {
  width: 90%;
  max-width: 32rem;
  min-width: 22rem;
}

.buttonMenu {
  cursor: default !important;
}

#coverReviews .popup p {
  min-height: 0rem;
}

/****** Style Star Rating Widget *****/

.star-rating {
  font-size: 2.5rem;
  margin-top: 1rem;
}
.star-rating span {
  width: 40px; /* Adjust based on your icon size */
  height: 30px; /* Adjust based on your icon size */
  display: inline-block;
  position: relative;
}

.differentSize {
  width: 13px;
  height: 30px;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
}

.star-rating p {
  font-size: 1.8rem;
}

.col2-ProductDescription h6 {
  font-size: 1.6rem;
  color: #fb3640;
  margin: 1rem 1rem 1rem 1rem;
}

.hoverMouse text {
  vertical-align: text-bottom !important;
}

.chart-container {
  position: relative;
  min-height: 50px; /* Set your desired minimum height */
}

.popup-inner #imageOnProfile {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.profileUser .giveReview:hover {
  background-color: #0692e3bb;
}

.adaptScreen {
  width: 80%;
  border-radius: 10rem;
  aspect-ratio: 1;
  object-fit: cover;
}
